@tailwind base;
@tailwind components;
@tailwind utilities;

/* There are a lot of these in the codebase, so in this case a default made sense.  */
p {
    margin-top: 1em;
    margin-bottom: 1em;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Work Sans, 'Open Sans', sans-serif, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-family: Work Sans;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

td {
    vertical-align: middle !important;
}

.hs-form-iframe {
    width: 100% !important;
}
